import bonk from './assets/img/Bonk.png';
import logo from './assets/img/Logo.png';
import prime from './assets/img/Prime.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://twitch.tv/towdan">
          <img src={logo} className="logo" alt="towdan" />
        </a>
        <p>Any primers? <img src={prime} className="prime-logo" alt="Twitch Prime"></img></p>
        <p><img src={bonk} className="bonk" alt="Bonk"></img>  horny jail   <img src={bonk} className="bonk" alt="Bonk"></img></p>
      </header>
    </div>
  );
}

export default App;
